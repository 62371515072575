import "./../Stylesheets/Main.css"

import { useState } from "react";
import axios from "axios";

import NewsImage from "../images/news/batavus.png"



const FormCard = () => {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
      });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false)

    const apiAddress = "https://schoenicke.org/api/contact-form-action.php"


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
      };

    const handleSubmit = (e) => {
        e.preventDefault();


        setSuccess(false);
        const formErrors = {};
        const errorMessage = "Pflichtfeld"

        if (formData.name.trim() === "") {
          formErrors.name = errorMessage;
        }

        if (formData.email.trim() === "") {
          formErrors.email = errorMessage;
        }

        if (formData.message.trim() === "") {
          formErrors.message = errorMessage;
        }

        if (Object.keys(formErrors).length > 0) {
          setErrors(formErrors);
        } else {
          setIsLoading(true);
          axios
          .post(apiAddress, JSON.stringify(formData))
          .then((res) => {
            console.log(res.data)
            setFormData({
              name: "",
              email: "",
              message: "",
            });
            setErrors({});
            setIsLoading(false);
            setSuccess(true);
          }).catch((error) => {
            console.error("Error submitting form:", error);
              setIsLoading(false);
          });
          
        }
      };

    return (
    
    <div className="contact-card-wrapper ">
      <h1 className="contact-title card-title-shadow title-left" >
                Kontakt
        </h1>
        <form className="contact-card card-shadow" onSubmit={handleSubmit}>
            <label>
                Name:
                {errors.name && (
                <span className="contact-error">{errors.name}</span>
              )}
            </label>
            <input type="text" name="name" value={formData.name} onChange={handleChange} className="contact-input" />
            <label>
                Email Adresse:
                {errors.email && (
                <span className="contact-error">{errors.email}</span>
              )}
            </label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} className="contact-input"/>
            <label>
                Nachricht:
                {errors.message && (
                <span className="contact-error">{errors.message}</span>
              )}
            </label>
            <textarea name="message" value={formData.message} onChange={handleChange} className="contact-message contact-input"/>
            <div className="submit-area">
              <button type="submit" disabled={isLoading} className="call-to-action contact-submit">
                      Senden
              </button>
              {
                success && (
                  <p className="contact-success">Nachricht gesendet!</p>
                )
              }
            </div>
            
        </form>
    </div>
    );
}

export default FormCard;