import { useContext } from "react";
import "./../Stylesheets/Main.css"
import { CookieContext, BannerContext } from "./MainPage";

const ContactMaps = () => {

    const {consent, setConsent} = useContext(CookieContext)
    const {showBanner, setShowBanner} = useContext(BannerContext)


    return (
        <div className="contact-wrapper">
            <h1 className="anfahrt-title">Anfahrt</h1>
        <div className="maps-wrapper background-text">
           {consent && (<iframe 
            className="maps-iframe"
            src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Guido%5C's%20Fahrradwelt%20Morgensternstra%C3%9Fe%201%2C%20Berlin%2C%20Germany+(Titel)&amp;ie=UTF8&amp;t=&amp;z=19&amp;iwloc=B&amp;output=embed" 
            frameBorder="0" 
            marginHeight="0" 
            marginWidth="0"
            >
            </iframe>)}

            {consent && (<iframe 
            className="maps-iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2432.8311622450533!2d13.322066815968205!3d52.427856850805895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85ae6b7df1ec7%3A0xcc12516f816fefad!2sGuido%C2%B4s+Fahrradwelt+Verkauf+und+Reparatur!5e0!3m2!1sde!2sde!4v1538491166170&zoom=15"
            frameBorder="0" 
            marginHeight="0" 
            marginWidth="0"
            >
            </iframe>)}

            {! consent && (
            <div id="cookie-warning">
                <p>
                    Bitte Aktualisieren Sie Ihre Privatsphäre Einstellungen, um die eingebetteten Maps Karten, nutzen zu können.
                </p>
                <button onClick={() => {setShowBanner(true)}} className="call-to-action contact-submit"> 
                    Privatsphäre Einstellungen
                </button>
            </div>
            )}      
        </div>
        </div>			
    );
}

export default ContactMaps;