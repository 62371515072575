import "./../Stylesheets/Main.css"

import NewsImage from "../images/news/batavus.png"
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const NewsCard = () => {

    const hostname = "127.0.0.1"
    const backendPort = 3001

    const apiAddress = "https://schoenicke.org/api/news-fetch.php"

    const [newsData, setNewsData] = useState([]);

    const navigate = useNavigate();

    const fetchGetArticles = async () => {
      const res = await fetch(`http://${hostname}:${backendPort}/news`);
      const data = await res.json();
      setNewsData(data);
    }


    const getData=()=>{
      
      axios
      .get(apiAddress)
      .then((res) => {
        setNewsData(res.data);
      }).catch((error) => {
        console.error("Error fetching News:", error);
      });
      }
    

    const setData = (data) => {
      setNewsData(data[0]);
    } 

    useEffect(()=>{
      getData()
      },[])

    let currentText = "";  
    let currentImage = "";
    currentText = Object(newsData[0]).text;
    currentImage = Object(newsData[0]).img;

    return (
    <div className="news-card-wrapper center-div">
        <h1 className="section-title title-right card-title-shadow">
            Was ist neu
        </h1>
        <div className="news-card card-shadow">
        <img src={currentImage} alt="News Thumbnail" className="news-image" />
        <div className="news-text-wrapper">
            <p className="news-text">
                {currentText}
            </p>
            <button type="button" className="call-to-action" onClick={() => {navigate("/news")}}>
                Mehr News
            </button>
        </div>
        </div>
    </div>
    );
}

export default NewsCard;