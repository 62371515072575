import "../../Stylesheets/Main.css"

import { useState } from "react";
import {Squash} from "hamburger-react";
import { useRef } from "react";
import { useClickAway}  from "react-use"

const NavMobile = ({ ParallaxRef }) => {

    const [isOpen, setOpen] = useState(false)
    const ref = useRef(null)

    useClickAway(ref, () => setOpen(false));

    return (
        <div ref={ref} className="mobile-menu-wrapper">
            <Squash toggle={setOpen} toggled={isOpen} />
            { isOpen && (
            <nav className = "main-menu-mobile">
                <a href="#" className="mobile-menu-item" >
                    Start
                </a>
                <hr className="mobile-menu-seperator"/>
                <a href="#service" className="mobile-menu-item" >
                    Service
                </a>
                <hr className="mobile-menu-seperator"/>
                <a href="#produkte" className="mobile-menu-item" >
                    Produkte
                </a>
                <hr className="mobile-menu-seperator"/>
                <a href="#kontakt" className="mobile-menu-item" >
                    Kontakt
                </a>
            </nav>)}
        </div>
        
    );
}

export default NavMobile