import {Parallax, ParallaxLayer} from '@react-spring/parallax';
import { useRef, useState, useEffect, createContext, useContext } from 'react';

import Header from './Header/Header.js';
import CookieConsent from './CookieConsent';
import NewsCard from './NewsCard';
import Address from './Address';
import LeasingCard from './LeasingCard';
import ServiceFinanzierung from './ServiceFinanzierung';
import BackgroundImage from './BackgroundImage';
import EbikeCard from './EbikeCard';
import ServiceWerkstatt from './ServiceWerkstatt';
import ContactMaps from './ContactMaps';
import FormCard from './FormCard';
import NewsUpload from './NewsUpload';
import Footer from './Footer/Footer.jsx';
import Products from './Products';

import { PageContext } from "../App.js"
import { BannerContext } from './MainPage.jsx';

import Background1 from "../images/background/hermann1.png";
import Background2 from "../images/background/morgenstern3.png";
import Background3 from "../images/background/fahrrad1.png";
import Background4 from "../images/background/schlaeuche.png";
import Background5 from "../images/background/hermann3.png";



const DesktopMain = () => {
  const parallax = useRef();

  const [windowWidth, setWidth] = useState(0.5);
  const {page, setPage} = useContext(PageContext);
  const {showBanner, setShowBanner} = useContext(BannerContext);

  const initialScroll = () => {
    if(window.innerWidth < 900){
      return
    }
    console.log(page);

    if(page === "Service"){
      parallax.current.scrollTo(1)
    }
    else if(page === "Produkte"){
      parallax.current.scrollTo(3)
    }
    else if(page === "Kontakt"){
      parallax.current.scrollTo(4.1)
    }

    setPage("");
  }

  useEffect(() => {

    initialScroll();

  }, [windowWidth]);
  

  return (
    <div className={'body-wrapper'}>
 

      <Parallax pages={7.5} ref={parallax}>    

        {/* Background Images */}
        
        <ParallaxLayer offset={0} speed={0} >
          <div className={'first-background'}>
            <BackgroundImage Path={Background1}></BackgroundImage>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0} >
          <BackgroundImage Path={Background2} ></BackgroundImage>
        </ParallaxLayer>

        <ParallaxLayer offset={1.9} speed={0} >
          <BackgroundImage Path={Background4} ></BackgroundImage>
        </ParallaxLayer>

        <ParallaxLayer offset={2.8} speed={0} >
          <BackgroundImage Path={Background3} ></BackgroundImage>
        </ParallaxLayer>

        <ParallaxLayer offset={4.1} speed={0} >
          <BackgroundImage Path={Background5} ></BackgroundImage>
        </ParallaxLayer>
      

        {/* Sections */}

        <ParallaxLayer offset={0.7} speed={0.1} >
          <Address></Address>
        </ParallaxLayer>

        <ParallaxLayer offset={1.7} speed={0.1} >
          <ServiceFinanzierung></ServiceFinanzierung>
        </ParallaxLayer>
        
        <ParallaxLayer offset={2.8} speed={0.3} >
          <ServiceWerkstatt></ServiceWerkstatt>
        </ParallaxLayer>

        <ParallaxLayer offset={3.7} speed={0.3} >
          <Products></Products>
        </ParallaxLayer>

        <ParallaxLayer offset={5} speed={0} >
          <ContactMaps></ContactMaps>
        </ParallaxLayer>

        <ParallaxLayer 
          offset={5.7} 
          speed={0} >
          <Footer ParallaxRef={parallax}></Footer>
        </ParallaxLayer>


        {/* Cards */}

        <ParallaxLayer 
          offset={0.5} 
          speed={1} 
          className='main-width'>
          <NewsCard></NewsCard>
        </ParallaxLayer>
        
        <ParallaxLayer 
          offset={1.5} 
          speed={1} 
          className='main-width'>
          <LeasingCard></LeasingCard>
        </ParallaxLayer>


        <ParallaxLayer 
          offset={2.6} 
          speed={0.6} 
          className='main-width'>
          <EbikeCard></EbikeCard>
        </ParallaxLayer>

        <ParallaxLayer 
          offset={4.7} 
          speed={1.5} 
          className='main-width'>
          <FormCard></FormCard>
        </ParallaxLayer>

      </Parallax>

      <Header ParallaxRef={parallax}></Header>

    {
      showBanner && (
        <CookieConsent></CookieConsent>
      )
    }
      
    </div>
    );
}

export default DesktopMain;