import '../Stylesheets/Main.css';

import PageHeader from './Header/PageHeader';
import Datenschutz from './Datenschutz';

function NewsPage() {

    return(
      <div className='news-page'>
        <PageHeader></PageHeader>
        <Datenschutz></Datenschutz>
        
      </div>
      );
  }

export default NewsPage;