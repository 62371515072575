import "../../Stylesheets/Main.css"
import NavDesktop from "./NavDesktop.js"
import NavMobile from "./NavMobile.jsx"
import logoIcon from "../../images/logo-icon.png"
import logoTitle from "../../images/logo-title.png"

const Header = ({ ParallaxRef }) => {
    return (
    <div className = "header-wrapper">
        <div>
            <img src = {logoIcon} alt="Guidos Fahrradwelt Logo" className = "header-icon" />
        </div>

        <div className = "header-box card-shadow">
            <img src= {logoTitle} alt="Guidos Fahrradwel" className = "header-title" />
            <NavDesktop ParallaxRef={ParallaxRef}></NavDesktop>
            <NavMobile ParallaxRef={ParallaxRef}></NavMobile>
        </div>
    </div>
    );
}

export default Header