import "../../Stylesheets/Main.css"

const FooterNavDesktop = ({ ParallaxRef }) => {
    return (
        <nav className = "footer-nav">
            <a href="#" className="footer-nav-item" onClick={() => ParallaxRef.current.scrollTo(0)}>
                Start
            </a>
            <a href="#" className="footer-nav-item" onClick={() => ParallaxRef.current.scrollTo(1)}>
                Service
            </a>
            <a href="#" className="footer-nav-item" onClick={() => ParallaxRef.current.scrollTo(3)}>
                Produkte
            </a>
            <a href="#" className="footer-nav-item" onClick={() => ParallaxRef.current.scrollTo(4.1)}>
                Kontakt
            </a>
        </nav>
    );
}

export default FooterNavDesktop