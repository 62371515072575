import "../../Stylesheets/Main.css"
import LogoIcon from "../../images/logo-icon.png"
import LogoTitle from "../../images/logo-title.png"

import FooterNavMobile from "./FooterNavMobile"
import FooterNavDesktop from "./FooterNavDesktop"

import { useNavigate } from 'react-router-dom';
import { useContext } from "react"

import { BannerContext } from "../MainPage"
import { MobileContext } from "../MainPage"

const Footer = ({ ParallaxRef }) => {

    const navigate = useNavigate();
    const {showBanner, setShowBanner} = useContext(BannerContext)
    const {mobile, setMobile} = useContext(MobileContext)

    return (
         <div className="footer-wrapper">

            <div className="footer-nav-wrapper">

                <img src={LogoTitle} alt="Guidos Fahrradwelt" className="footer-logo-title"/>
            {mobile
                ? <FooterNavMobile />
                : <FooterNavDesktop ParallaxRef={ParallaxRef} />
            }

                <nav className="footer-nav">
                    <a href="#" className="footer-nav-item" onClick={() => {navigate("/datenschutz")}}>
                        Datenschutzerklärung
                    </a>
                    <a href="#" className="footer-nav-item" onClick={() => {setShowBanner(true)}}>
                        Cookie-Einverständniserklärung
                    </a>
                </nav>

                <img src={LogoIcon} alt="Logo Guidos Fahrradwelt" className="footer-logo-icon"/> <br />
            </div>
            

            <div>
                <h2>Impressum</h2>
                <p>
                    Guido's Fahrradwelt <br />
                    Inhaber: Guido Schönicke <br />
                    <br />
                    Hermannstraße 32 <br />
                    12049 Berlin - Neukölln <br />
                    <br />
                    Tel.: (030) 622 327 5 <br />
                    Fax: (030) 666 45 969 <br />
                    <br />
                    Morgensternstraße 1 <br />
                    12207 Berlin - Steglitz <br />
                    <br />
                    Tel: (030) 270 151 61 <br />
                    Fax: (030) 270 151 62 <br />
                    <br />
                    E-Mail: info@guidos-fahrradwelt.de <br />
                    <br />
                    Öffnungszeiten <br />
                    Dienstag - Freitag: 10:00 - 18:30 <br />
                    Samstag: 10:00 - 14:00 <br />
                    Sonntag und Montag geschlossen. 
                </p>
            </div>
            
         </div>
    );
}

export default Footer;