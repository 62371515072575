
import '../Stylesheets/Main.css';
import { useState, useEffect } from 'react';
import axios from 'axios';

import Header from './Header/Header';



function NewsList() {


    const apiAddress = "https://schoenicke.org/api/news-fetch.php"

    const [newsData, setNewsData] = useState([]);

    const getData=()=>{
      
      axios
      .get(apiAddress)
      .then((res) => {
        setNewsData(res.data);
      }).catch((error) => {
        console.error("Error fetching News:", error);
      });
      }

    useEffect(()=>{
      getData()
      },[])

    return(
      <div className='news-list'>
        {newsData.map(item => (
        <article className='news-article' key={item.id}>
             <img src={item.img} alt="News Thumbnail" className="news-image" />
            <div className="news-text-wrapper">
                <p className="news-text">
                    {item.text}
                </p>
                <p className='news-date'>
                    {item.date}
                </p>
            </div>
        </article>
        ))}
      </div>
      );
  }

export default NewsList;
