import "./../Stylesheets/Main.css"

import { useContext, useState } from "react";

import NewsImage from "../images/news/batavus.png"
import { CookieContext, BannerContext } from "./MainPage";


const CookieConsent = () => {

    const {consent, setConsent} = useContext(CookieContext)
    const {showBanner, setShowBanner} = useContext(BannerContext)

    const giveConsent = (response) => {
        setConsent(response)
        setShowBanner(false)
    }

    return (
    <div className="cookie-banner" id="cookie-banner">
        <h2>Privatsphäre Einstellung</h2>
        <p>
        Diese Seite nutzt Website-Tracking-Technologien von Dritten, um ihre Dienste anzubieten. Um die Google Maps Einbindung auf unserer Kontaktseite nutzen zu können, müssen Sie der Platzierung der Analyse-Cookies von Google zustimmen. 
        </p>
        <button className="option-button" onClick={() => giveConsent(false)}> Ablehnen </button>
        <button className="call-to-action second-button" onClick={() => giveConsent(true)} > Akzeptieren </button>
    </div>
    );
}

export default CookieConsent;