import "../../Stylesheets/Main.css"

import { useState } from "react";
import {Squash} from "hamburger-react";
import { useRef } from "react";
import { useClickAway}  from "react-use"

const FooterNavMobile = () => {
    return (
        <nav className = "footer-nav">
            <a href="#" className="footer-nav-item" >
                Start
            </a>
            <a href="#service" className="footer-nav-item" >
                Service
            </a>
            <a href="#produkte" className="footer-nav-item" >
                Produkte
            </a>
            <a href="#kontakt" className="footer-nav-item" >
                Kontakt
            </a>
        </nav>      
    );
}

export default FooterNavMobile